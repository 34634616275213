.pbx-annocement-pagination-btt {
    padding: 12px !important;
    padding-bottom: 12px !important;
}

.pbx-annoucement-tablecell-btt {
    border-bottom: 0px solid rgba(252, 252, 252, 1);
}
.upload-input-field div:nth-child(1) {
    padding: 0px !important;
    margin-left: 15px;
}
.pbxclass-select-btt {
    border-radius: 8px;
    padding: 0px;
    width: 210px !important;
    height: 42px;
    overflow: hidden;
    padding-top: 2px;
}
.pbxannoucement-inputlabel-btt {
    padding-top: 11px;
    font-size: 0.955rem;
    color: #212121;
    font-weight: 600;
    padding-right: 25px;
}
.pbx-annoucement-tablecell {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
}

.pbx-annoucement-empty-msg {
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding-top: 100px;
    padding-bottom: 100px;
}
.pbx-annoucement-title-tbl {
    background-color: rgb(247, 250, 255);
}
.pbx-annoucement-parent-card {
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    border-color: #dee0e1;
    width: 100%;
}

.ps-height-signle-row-head {
    max-height: calc(100vh - 88px) !important;
}

.ps-height-double-row-head {
    max-height: calc(100vh - 153px) !important;
}
