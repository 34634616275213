.invoice001-container {
    width: 600px;
    height: 100%;
    background: white;
    box-shadow: 0 2px 5px rgba(75, 75, 75, 0.2);
    border: 1px #d3d3d3 solid;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border-radius: 4px;
}

.container-billing {
    border-radius: 4px;
}

.student-billing-colorchange {
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 4px;
}

.main-home-studentbilling-div {
    max-width: 920px;
    max-height: 100%;
    margin: auto;
    overflow: hidden;
    display: flex;
}

.invoice001-header-divider {
    margin: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: #000 !important;
    border-bottom-width: 1px !important;
}

.invoice001-page {
    margin: 8px;
    border-radius: 4px;
}

.invoice001-border {
    border-width: 1px;
    border-color: #2b3a55;
    border-style: solid;
}

.invoice001-header {
    margin: 5px;
    border-radius: 8px;
}

.invoice001-header-title {
    font-weight: 700;
    font-size: 18px;
    color: #000;
    text-align: center;
    line-height: 0px;
    padding: 4px 0px 6px 0px;
}

.invoice001-header-content-logo {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 3px;
}

.paragraph-header {
    font-weight: 700;
    font-size: 18px;
    color: #000;
    text-align: center;
    line-height: 0px;
}
.invoice001-header-content {
    display: flex;
    justify-content: space-between;
    padding: 6px 8px 2px 8px;
}
.invoice001-header-content-right {
    float: right;
    padding-top: 2px;
}

.invoice001-content-1-right {
    text-align: right;
}

.invoice001-footer-right {
    text-align: right;
    margin-bottom: 100px;
}

.invoice001-text-color {
    color: #000;
}

.invoice001-header-content-right-1 {
    line-height: 0px;
}
.invoice001-header-content-right-2 {
    text-align: right;
    line-height: 3px;
}

.invoice001-header-content-right-3 {
    text-align: right;
    line-height: 0px;
}

.invoice001-content-1 {
    display: flex;
    justify-content: space-between;
    padding: 0px 6px 0px 6px;
    color: #000;
}

.invoice001-footer {
    display: flex;
    justify-content: space-between;
    padding: 6px 6px 10px 6px;
}
.invoice001-content-1-lineheight {
    line-height: 2px;
}

.invoice001-content-1-address {
    line-height: 18px;
    margin: -6px 0px -6px 0px;
}

.invoice001-footer-heading {
    font-size: 16px;
    line-height: 0px;
}
.invoice001-footer-text {
    font-size: 14px;
    line-height: 0px;
    color: #000 !important;
}
.amount-billing-head-3 {
    font-size: 14px;
    opacity: 0;
    line-height: 0px;
}

.invoice001-content-2 {
    padding: 0px 4px 4px 4px;
}

.invoice001-table {
    width: 100%;
    border-collapse: collapse;
}
.tableheader-one-md {
    border: 1px solid #000;
    color: #000;
    font-weight: 700;
    text-align: left;
    padding: 4px;
}

.invoice001-table-header {
    border: 1px solid #000;
    color: #000;
    font-weight: 700;
    text-align: left;
    padding: 4px;
    border-color: #000000;
    background: #fff;
}

.tablebody-one-md {
    border: 1px solid #000;
    text-align: left;
    font-size: 15px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
}

.invoice001-table-body {
    border: 1px solid #000;
    text-align: left;
    font-size: 15px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
    color: #000;
    border-color: #000000;
}

.invoice001-content-1-header {
    line-height: 0px;
}

.invoice001-content-1-left {
    width: 50%;
    text-align: left;
}
