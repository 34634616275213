.annoucement-grid-container {
    margin-bottom: -15px;
    margin-top: -10px;
}
.pbx-annoucement-spacingbtt {
    margin-bottom: -15px;
    margin-top: -35px;
}
.annoucement-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
}
.annoucement-user-subcard {
    padding: 0px 0px 0px 0px;
    margin-bottom: -24px;
    display: block;
    top: 0;
    left: 0;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    transform-origin: right top;
    padding-top: 8px;
    background-color: #e8eaf6;
}
.annoucement-user-subcard::-webkit-scrollbar {
    display: block;
    width: 0;
    background-color: transparent;
}

.pbx-annoucement-subcard-grid {
    width: 100%;
    border-radius: 8px;
    border: none;
}

.pbx-annoucement-heading {
    display: flex;
    justify-content: center;
    padding-top: 200px !important;
    padding-bottom: 200px;
    margin-left: 8px;
    background-color: white;
    height: 450px;
}
